.footer {
  @extend %relative;
  @extend %wrapper;
  height: 243px;
  background-color: var(--color-main);
  @include tablet {
    height: auto;
  }
  * {
    @extend %color-light;
  }
  &__adress {
  }
  &__button {
  }
  &__email {
  }
  &__link {
    @extend %font-subtitle;
    font-weight: 300;
  }
  &__phone {
  }
  &__row {
    @extend %flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    @include tablet {
      &:nth-of-type(n + 2) {
        flex-direction: column;
        margin-top: 50px;
      }
    }
  }

  &__social {
    @extend %flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__social-title {
    @extend %font-subtitle;
  }
  &__socila-item {
    @extend %font-subtitle;
    margin-left: 40px;
    @extend %color-red;
    @include tablet {
      margin-left: 0;
    }
  }
  &__wrapper {
    @extend %flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 0 30px;
    height: 100%;
  }
  &__writeitem {
    margin-right: 34px;
    @extend %font-subtitle;
  }
  &__writeus {
    @extend %flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__copyright {
    // margin-left: 30px;
  }
  &__privacy {
  }
  &__row {
    &--policy {
      justify-content: flex-end;
      * {
        color: var(--color-second);
      }
    }
  }
}
