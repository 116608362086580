.menu {
  // display: none;
  $self: &;
  &__col {
  }
  &__icon-wrapper {
    z-index: 10000;
    position: fixed;
    right: calc(-1 * #{toVw(40, 80)});
    top: 0;
    width: toVw(80, 80);
    height: toVw(80, 80);
    background-color: var(--color-second);
    border-radius: 50%;
    transform: translateX(-70%);
    @extend %flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  &__icon {
    @extend %no-select;
    color: var(--color-white);
    transform-origin: center;
    transform: rotate(-90deg) translateY(-20%);
    transition: all 0.3s ease-in-out;
  }
  &__navigation {
    @extend %flex;
    flex-direction: column;
  }
  &__navigation-link {
    color: var(--color-light);
  }
  &__navigation-link {
    @extend %font-menu;
    padding-bottom: toVw(25);
    @include tablet {
      padding-bottom: 5vh;
    }
    @extend %relative;
    &::after {
      content: "";
      @extend %absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-image: url("../images/UI/hover_menu.svg");
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
    &:hover::after {
      opacity: 1;
      visibility: visible;
    }
  }
  &__navigation-wrapper {
    @extend %flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 15vh calc(var(--grid-margin) + var(--grid-col-width)) toVw(50) 0;
    @include tablet {
      // padding-bottom: 50px;
      padding: 15vh var(--grid-gap) 50px;
    }
  }
  &__row {
    @extend %grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5vw;
    @include tablet {
      &:last-of-type {
        grid-template-columns: 1fr;
        grid-row: auto auto;
      }
    }
  }
  &__title {
    @extend %absolute;
    @extend %font-hero-title;
    color: var(--color-white);
    transform: rotate(-90deg);
    transform-origin: center;
  }
  &__title-wrapper {
    @extend %flex;
    align-items: center;
    justify-content: center;
    width: var(--grid-col-withgap);
    @include tablet {
      display: none;
    }
  }
  &__wrapper {
    @extend %flex;
    flex-direction: row;
    visibility: hidden;
    opacity: 0;
    z-index: 10001;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(
      100vw - var(--grid-margin) - var(--grid-col-width) * 2 - var(--grid-gap)
    );

    border-radius: calc(var(--grid-gap) / 2) 0 0 calc(var(--grid-gap) / 2);
    background-color: var(--color-second);
    @include tablet {
      width: 100%;
    }
  }
  &--contact {
    @extend %flex;
    flex-direction: column;
    * {
      color: var(--color-white);
      @extend %font-menu-contact;
    }
  }
  &--contact-item {
    color: var(--color-white);
  }
  &__close-wrapper {
    @extend %absolute;
    right: 20px;
    top: 20px;
    width: toVw(45, 45);
    height: toVw(45, 45);
    cursor: pointer;
  }
  &__close-icon {
    @extend %contain;
  }
  &--nav {
  }
  &__social-title,
  &__button-title {
    color: var(--color-white);
    @extend %font-subtitle;
    margin-bottom: 20px;
  }
  &__button-write {
    margin-bottom: 10vh;
  }
  &__social-links {
    @extend %flex;
    flex-direction: column;
  }
  &__social-link {
    @extend %font-subtitle;
    color: var(--color-light);
    padding-bottom: 10px;
    &.menu__social-link--instagram {
      cursor: url("data:image/svg+xml,%3Csvg  id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%231d1d1b;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eiden_team%3C/title%3E%3Cpath class='cls-1' d='M1.54,2s34.33-3.42,35.17,3.5,4.5,30.83-2.17,32.17c0,0-31.33,3.25-32-1.33S1.21,14.2,1.87,9.53,1.69,4.13,7.54,3.2c9.87-1.58,30.25-.58,30.25-.58'/%3E%3Cpath class='cls-1' d='M13,17.4s7.06-6.06,13,1.75S15,34,12.37,25.28,15.93,11.84,22.5,13.53,29.25,26,25.62,28.9'/%3E%3Cpath class='cls-1' d='M28.18,8.84s.67,4,2.9,1.85c1.54-1.47.66-3-.9-3.31a2.91,2.91,0,0,0-3,2.44'/%3E%3C/svg%3E")
          20 20,
        pointer;
    }
    &.menu__social-link--facebook {
      cursor: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%231d1d1b;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eiden_team%3C/title%3E%3Cpath class='cls-1' d='M18.37,35.63c-4.52.9-1.6-4.74-1.31-9.27a8.14,8.14,0,0,0-.52-3.58c-.69-1.05-2.24-.46-3.14-.48-1.3,0-.56-2.94-.47-4.44s3.5-.31,4.25-.62.25-1.27.91-6.72S26,6,27.62,6.23s1.14,3.53.06,4.06c-6-.63-5.36,5.41-4.66,6.59s3.78.39,4.16.69.12,3.88,0,4.31-.15.5-3.15.85-1,7.9-.87,10.56c.06,1.71-2.6,1.93-5.85.78'/%3E%3Cpath class='cls-1' d='M2.68,17.68S.9,4.68,5.37,3.12,27.31,1.24,30.81,1.37,38.25,5,38.68,8.12s1.06,21.66-.62,25.44-8.28,5.56-18.87,5-15.81-2.31-16.5-7.5-3.13-24-1-25.56'/%3E%3C/svg%3E")
          20 20,
        pointer;
    }
    &.menu__social-link--behance {
      cursor: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%231d1d1b;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eiden_team%3C/title%3E%3Cpath class='cls-1' d='M6.2,1.07S32.08.64,34.45,1.7s4.63,8.56,4.5,19.88-.56,16-5.5,16.75S2.58,39.89,1.7,36.82,1,12.14,1.33,8.2,6,2.39,9.33,2.32'/%3E%3Cpath class='cls-1' d='M5,12.94s.26,15.17,1.26,15.33,10,1.27,11.84-.46,1.5-6.73,0-8.15c-1.16-1.1-1.69-.41.77-3.22,1-1.16,1.21-4.39-.92-5.32C13,9,6.17,9.59,6,10.78s-.5,4.83.75,6.58'/%3E%3Cpath class='cls-1' d='M9.39,16.53S8.9,14,9.86,14s4.46-.42,5.07.38S13.09,17,12.16,17.19c-3,.68-2.82-.94-2.82-.94'/%3E%3Cpath class='cls-1' d='M7.68,21s7-.68,7.83.35.46,3.14-1.06,3.27-4.93,0-5.27-.37,0-3.65,0-3.65'/%3E%3Cpath class='cls-1' d='M24,22.68s9.65.05,10-.69-1.63-7.29-6.82-7-7.7,4.6-5.69,9.76,11.12,3.5,12.58.23c0,0,.62-1.18.15-1.38s-4.37.17-4.48.92c-.26,1.64-3.68.79-4.38.21a3.81,3.81,0,0,1-.9-2.38'/%3E%3Cpath class='cls-1' d='M25.13,20.24s4.66.5,5-.26S26,16.44,25.13,20.24Z'/%3E%3Cpath class='cls-1' d='M23.24,12.43s8.87.36,9.43-.36.87-3.14-8.24-1'/%3E%3C/svg%3E")
          20 20,
        pointer;
    }
  }
}
