.live {
  @extend %relative;
  @extend %section;
  $self: &;
  &__title {
    @extend %font-title;
  }
  &__title-wrapper {
    @extend %wrapper;
    text-align: center;
  }

  &__content {
    @extend %absolute;
    top: 50%;
    transform: translateY(-50%);
    @include tablet {
      display: none;
    }
  }
  &__grid {
    @extend %grid;
    grid-template-columns:
      repeat(3, var(--grid-col-width)) calc(
        var(--grid-col-width) + var(--grid-col-withgap)
      )
      repeat(3, var(--grid-col-width));
    gap: var(--grid-gap);
    grid-template-rows: 1fr;
    height: 60vh;
    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10vw;
      grid-template-rows: 0.5fr 1fr 0.5fr;
      height: auto;
    }
  }
  &__grid-image {
    @extend %contain;
    @extend %absolute;
    height: auto;
    cursor: pointer;
    @include tablet {
      position: relative;
    }
  }
  &__grid-item {
    @extend %relative;
    cursor: pointer;
    width: 100%;
    &--preview {
      @extend %flex;
      flex-direction: column;
      align-items: center;
      @include tablet {
        grid-column: 1/4;
        #{$self}__preview-button-wrapper {
          display: none;
        }
      }
    }
    &:nth-of-type(1) {
      #{$self}__grid-image {
        top: 10%;
        transform: translateY(-10%);
        @include tablet {
          // top: unset;
          // transform: unset;
        }
      }
    }
    &:nth-of-type(2) {
      #{$self}__grid-image {
        top: 90%;
        transform: translateY(-90%);
        @include tablet {
          // top: unset;
          // transform: unset;
        }
      }
    }
    &:nth-of-type(3) {
      #{$self}__grid-image {
        top: 0;
        transform: translateY(0);
        @include tablet {
          // top: unset;
          transform: translateY(20%);
        }
      }
    }
    &:nth-of-type(5) {
      #{$self}__grid-image {
        top: 50%;
        transform: translateY(-50%);
        @include tablet {
          // top: unset;
          transform: translateY(0);
        }
      }
    }
    &:nth-of-type(6) {
      #{$self}__grid-image {
        top: 70%;
        transform: translateY(-70%);
        @include tablet {
          top: 0;
          transform: translateY(0);
        }
      }
    }
    &:nth-of-type(7) {
      #{$self}__grid-image {
        top: -10%;
        transform: translateY(10%);
        @include tablet {
          top: 30%;
          transform: unset;
        }
      }
    }
  }
  &__grid-item-wrapper {
  }
  &__grid-wrapper {
    @extend %relative;
    @extend %wrapper;
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__preiew-image {
    @extend %contain;
  }
  &__preview-button {
  }
  &__preview-button-wrapper {
    margin-top: 40px;
  }
  &__preview-wrapper {
    @include tablet {
      width: 100%;
    }
  }
  &__preview-button-tablet {
    display: none;
    @include tablet {
      display: inline-block;
    }
  }
  &__line {
    @extend %absolute;
    // @extend %contain;

    @extend %disable-events;
    &:nth-of-type(1) {
      left: 0;
      top: 20px;
      bottom: 0;
      width: calc(
        var(--grid-margin) + var(--grid-col-withgap) + var(--grid-col-width)
      );
    }
    &:nth-of-type(2) {
      left: calc(
        var(--grid-margin) + var(--grid-col-withgap) - var(--grid-gap) / 2
      );
      top: 100px;
      height: auto;
      width: calc(var(--grid-col-withgap) * 3);
    }
    &:nth-of-type(3) {
      right: 0;
      top: 80px;
      width: calc(
        var(--grid-col-withgap) * 3 + var(--grid-margin) + var(--grid-col-width)
      );
    }
  }
  &__line-image {
    @extend %contain;

    @extend %disable-events;
  }
  &__lines {
    @extend %absolute;

    @extend %disable-events;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
