.header {
  @extend %relative;
  &__logo {
    @extend %contain;
  }
  &__logo-wrapper {
    width: max(74px, calc(var(--grid-col-width)));
  }
  &__wrapper {
    @extend %wrapper;
    @extend %flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 27px;
  }
  &__lang-wrapper {
    @include tablet {
      margin-right: 30px;
    }
  }
}
