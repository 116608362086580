.hero {
  @extend %wrapper;
  @extend %relative;
  @extend %section;
  margin-top: 50px;
  $self: &;
  @include mobile {
    display: flex;
    flex-direction: column;
  }
  &__description {
    margin-left: 50px;
    width: calc(var(--grid-col-withgap) * 2);
    mark {
      background-color: transparent;
      @extend %relative;
      &:after {
        content: "";
        @extend %absolute;
        left: -5px;
        right: -5px;
        bottom: -5px;
        top: -5px;
        background-image: url(../images/mark01.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }
      &:nth-of-type(1)::after {
        background-image: url(../images/mark02.svg);
      }
    }
    @include mobile {
      margin-left: 0;
      margin-right: 20px;
      width: auto;
    }
  }
  &__game {
  }
  &__game-wrapper {
    margin-left: var(--grid-col-width);
    @extend %flex;
    flex-direction: row;
    align-items: flex-start;
    @include mobile {
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-left: 0;
    }
  }
  &__image {
    @extend %contain;
  }
  &__image-wrapper {
    @extend %absolute;
    width: calc(var(--grid-col-withgap) * 3);
    left: 0;
    top: 25%;
    z-index: 2;
    @include mobile {
      margin-bottom: 40px;
      width: 100%;
      position: relative;
      order: 2;
    }
  }
  &__showreel {
  }
  &__showreel-wrapper {
    z-index: 1;
    @extend %absolute;
    width: calc(var(--grid-col-withgap) + var(--grid-col-width));
    right: var(--grid-margin);
    top: 0;
    @include mobile {
      right: unset;
      margin-bottom: 30px;
      width: 100%;
      position: relative;
      order: 4;
    }
  }
  &__title {
    @extend %font-hero-title;
    @extend %disable-events;
    margin-bottom: 20px;
    @include mobile {
      margin-bottom: 10px;
    }
    &:nth-of-type(1) {
      margin-left: calc(var(--grid-col-width) + var(--grid-gap));
      @include mobile {
        margin-left: 0;
      }
    }
    &:nth-of-type(2) {
      margin-left: calc(var(--grid-col-withgap) * 3);
      @include mobile {
        margin-left: var(--grid-col-width);
      }
    }
    &:nth-of-type(3) {
      margin-left: calc(var(--grid-col-withgap) * 3);
      @include mobile {
        margin-left: var(--grid-col-width);
      }
    }
  }
  &__title-wrapper {
    @extend %relative;
    @extend %disable-events;
    z-index: 3;
    @include mobile {
      order: 1;
      margin-bottom: 10px;
    }
    &::after {
      @extend %no-select;
      content: "";
      @extend %absolute;
      left: calc(var(--grid-col-width) + var(--grid-gap));
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url("../images/UI/hero_line.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__video {
  }
  &__footer {
    @extend %relative;
    @extend %flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    // justify-content: space-between; uncomment when game will be added
    justify-content: flex-end;

    margin-top: 30px;
    @include mobile {
      margin-bottom: 30px;
      order: 3;
    }
    #{$self}__game-wrapper {
      @include mobile {
        display: none;
      }
    }
  }
  &__footer-mobile {
    display: none;
    order: 5;
    @include mobile {
      display: block;
    }
  }
}
.video {
}
