.jsPopupWrapper {
  position: fixed;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  background-color: var(--color-white);
}
.jsPopupVideoWrapper {
  width: 100%;
  height: 100%;
  padding: toVw(115) calc(var(--grid-gap) + var(--grid-col-width));
}
.jsPopupVideo {
  @extend %contain;
}
