* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-transform: lowercase;
}

a {
  text-decoration: none;
}

:root {
  --color-bg: #efedea;
  --color-white: #efedea;
  --color-main: #4d423d;
  --color-second: #bf6b47;
  --color-light: #e8be9e;
  --grid-margin: 5.2084vw;
  --grid-columns: 8;
  --grid-gap: 4.1667vw;
  --grid-col-width: 7.5521vw;
  --grid-col-withgap: 11.7188vw;
}

body,
html {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
body::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.5) inset;
  transition: background-color 5000s ease-in-out 0s;
}

html {
  background-color: var(--color-bg);
}

.form input,
.form textarea, .form__item, .form__group, .live__grid-wrapper, .live__grid-item, .live, .portfolio__title-link, .portfolio__title:nth-of-type(3), .portfolio__credentials, .portfolio, .cookies__position, .footer, .awards__item, .awards, .grid__item, .grid, .hero__footer, .hero__title-wrapper, .hero__description mark, .hero, .menu__navigation-link, .header, .button {
  position: relative;
}

.jsTeamImage, .jsTeamWrapper, .jsTeamhover, .form input[type=submit], .form__item-desc, .form__group:not(:last-of-type) .form__item::after, .submit-form__thankyou, .submit-form__close-wrapper, .live__lines, .live__line, .live__grid-image, .live__content, .jsPortfolioImage, .jsHoverPortfolio,
.jsPortfolio, .portfolio__title:nth-of-type(3)::after, .portfolio__credentials::after, .cookies__image-wrapper, .cookies__close-wrapper, .awards__item-wrapper, .awards__icon, .grid__item--active .grid__item-title, .hero__title-wrapper::after, .hero__showreel-wrapper, .hero__image-wrapper, .hero__description mark:after, .menu__close-wrapper, .menu__title, .menu__navigation-link::after, .button__switch::after {
  position: absolute;
}

.form__label, .form__group, .form, .submit-form__thankyou, .submit-form__title-wrapper, .live__grid-item--preview, .portfolio__titles, .cookies__wrapper, .footer__writeus, .footer__wrapper, .footer__social, .footer__row, .awards__item-wrapper, .awards, .grid__item--active .grid__item-description, .services, .hero__footer, .hero__game-wrapper, .menu__social-links, .menu--contact, .menu__wrapper, .menu__title-wrapper, .menu__navigation-wrapper, .menu__navigation, .menu__icon-wrapper, .header__wrapper {
  display: flex;
}

.live__grid, .awards__grid, .grid__item, .grid, .menu__row {
  display: grid;
}

.submit-form__title, .hero__title, .menu__title {
  font-size: Max(7.2917vw, 40px);
  line-height: Max(6.25vw, 40px);
}

.submit-form__title, .live__title, .portfolio__title-link, .portfolio__title, .awards__title, .services__title {
  font-size: Max(8.8542vw, 52px);
  line-height: Max(8.3334vw, 52px);
  color: var(--color-second);
  margin-bottom: 50px;
}
@media (orientation: portrait), (max-width: 780px) {
  .submit-form__title, .live__title, .portfolio__title-link, .portfolio__title, .awards__title, .services__title {
    margin-bottom: 30px;
  }
}

.submit-form__description, .footer__writeitem, .footer__socila-item, .footer__social-title, .footer__link, .awards__multiply, .menu__social-link, .menu__social-title, .menu__button-title {
  font-size: Max(1.823vw, 20px);
  line-height: Max(2.0834vw, 30px);
  font-weight: 900;
}

* {
  font-size: Max(1.0417vw, 17px);
  line-height: Max(1.3542vw, 22px);
}

.menu__navigation-link {
  font-size: Max(4.1667vw, 36px);
  line-height: Max(4.1667vw, 30px);
}

.menu--contact * {
  font-size: Max(1.9792vw, 20px);
  line-height: Max(2.6042vw, 30px);
}

.form__label {
  font-size: Max(4.1667vw, 35px);
  line-height: Max(4.1667vw, 35px);
}

.submit-form, .live__grid-wrapper, .live__title-wrapper, .portfolio, .footer, .hero, .header__wrapper {
  width: 100%;
  padding: 0 var(--grid-margin);
}

.live, .portfolio, .awards, .services, .hero {
  margin-bottom: 154px;
}
@media (orientation: portrait), (max-width: 780px) {
  .live, .portfolio, .awards, .services, .hero {
    margin-bottom: 80px;
  }
}

.services__grid-wrapper {
  width: min(90%, 1024px);
}

.awards__wrapper {
  width: min(80%, 700px);
}

.submit-form__img, .live__line-image, .live__preiew-image, .live__grid-image, .cookies__close-icon, .jsPopupVideo, .videoplayer__placeholder *, .hero__image, .menu__close-icon, .header__logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.submit-form__close, .awards__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer * {
  color: var(--color-white);
}

.footer__socila-item {
  color: var(--color-second);
}

.hero__title-wrapper::after, .menu__icon {
  user-select: none;
}

.live__lines, .live__line-image, .live__line, .videoplayer__image, .hero__title-wrapper, .hero__title, .cursor {
  pointer-events: none;
}

@font-face {
  font-family: "CASlalom";
  src: url("../fonts/CASlalom-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "CASlalom";
  src: url("../fonts/CASlalom-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
html {
  font-size: 18px;
}

* {
  font-family: CASlalom, sans-serif;
  color: var(--color-main);
  font-weight: 300;
}

.button {
  display: inline-block;
  text-align: center;
  padding: 13px 35px;
  border-radius: 20vmin;
  border: 3px solid var(--color-main);
  cursor: pointer;
  z-index: 1;
}
.button__switch {
  font-size: 18px;
  line-height: 18px;
  font-weight: 900;
}
.button__switch::after {
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(28px + 1rem);
  border-radius: 28px;
  background-color: var(--color-light);
  transition: width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: -1;
}
.button:hover .button__switch::after {
  width: 100%;
}
.button--invert {
  border-color: var(--color-light);
}
.button--invert .button__switch::after {
  background-color: var(--color-second);
}
.button--red .button__switch::after {
  background-color: var(--color-second);
}
.button--light {
  border-color: var(--color-white);
}
.button--light * {
  color: var(--color-white);
}
.button--light .button__switch::after {
  background-color: var(--color-light);
}
.button--lang {
  padding: 6px 20px;
  border: none;
}
.button--lang .button__switch::after {
  width: 38px;
}

@keyframes icon {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -18.75vw 0;
  }
}
@keyframes iconfix {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -360px 0;
  }
}
@keyframes show-portfolio {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.cursor {
  position: fixed;
  width: Max(4.6875vw, 90px);
  height: Max(4.6875vw, 90px);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  transform: translate(-50%, -50%);
}
.cursor--close .cursor__image {
  background-image: url("../images/UI/close_button.svg");
}
.cursor--play .cursor__image {
  background-image: url("../images/UI/play_button.svg");
}
.cursor__image {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.cursor__wrapper {
  width: 100%;
  height: 100%;
}

.header__logo-wrapper {
  width: max(74px, var(--grid-col-width));
}
.header__wrapper {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 27px;
}
@media (max-width: 1024px) {
  .header__lang-wrapper {
    margin-right: 30px;
  }
}

.menu__icon-wrapper {
  z-index: 10000;
  position: fixed;
  right: calc(-1 * Max(2.0834vw, 80px));
  top: 0;
  width: Max(4.1667vw, 80px);
  height: Max(4.1667vw, 80px);
  background-color: var(--color-second);
  border-radius: 50%;
  transform: translateX(-70%);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.menu__icon {
  color: var(--color-white);
  transform-origin: center;
  transform: rotate(-90deg) translateY(-20%);
  transition: all 0.3s ease-in-out;
}
.menu__navigation {
  flex-direction: column;
}
.menu__navigation-link {
  color: var(--color-light);
}
.menu__navigation-link {
  padding-bottom: 1.3021vw;
}
@media (max-width: 1024px) {
  .menu__navigation-link {
    padding-bottom: 5vh;
  }
}
.menu__navigation-link::after {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url("../images/UI/hover_menu.svg");
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.menu__navigation-link:hover::after {
  opacity: 1;
  visibility: visible;
}
.menu__navigation-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 15vh calc(var(--grid-margin) + var(--grid-col-width)) 2.6042vw 0;
}
@media (max-width: 1024px) {
  .menu__navigation-wrapper {
    padding: 15vh var(--grid-gap) 50px;
  }
}
.menu__row {
  grid-template-columns: 1fr 1fr;
  grid-gap: 5vw;
}
@media (max-width: 1024px) {
  .menu__row:last-of-type {
    grid-template-columns: 1fr;
    grid-row: auto auto;
  }
}
.menu__title {
  color: var(--color-white);
  transform: rotate(-90deg);
  transform-origin: center;
}
.menu__title-wrapper {
  align-items: center;
  justify-content: center;
  width: var(--grid-col-withgap);
}
@media (max-width: 1024px) {
  .menu__title-wrapper {
    display: none;
  }
}
.menu__wrapper {
  flex-direction: row;
  visibility: hidden;
  opacity: 0;
  z-index: 10001;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: calc(100vw - var(--grid-margin) - var(--grid-col-width) * 2 - var(--grid-gap));
  border-radius: calc(var(--grid-gap) / 2) 0 0 calc(var(--grid-gap) / 2);
  background-color: var(--color-second);
}
@media (max-width: 1024px) {
  .menu__wrapper {
    width: 100%;
  }
}
.menu--contact {
  flex-direction: column;
}
.menu--contact * {
  color: var(--color-white);
}
.menu--contact-item {
  color: var(--color-white);
}
.menu__close-wrapper {
  right: 20px;
  top: 20px;
  width: Max(2.3438vw, 45px);
  height: Max(2.3438vw, 45px);
  cursor: pointer;
}
.menu__social-title, .menu__button-title {
  color: var(--color-white);
  margin-bottom: 20px;
}
.menu__button-write {
  margin-bottom: 10vh;
}
.menu__social-links {
  flex-direction: column;
}
.menu__social-link {
  color: var(--color-light);
  padding-bottom: 10px;
}
.menu__social-link.menu__social-link--instagram {
  cursor: url("data:image/svg+xml,%3Csvg  id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%231d1d1b;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eiden_team%3C/title%3E%3Cpath class='cls-1' d='M1.54,2s34.33-3.42,35.17,3.5,4.5,30.83-2.17,32.17c0,0-31.33,3.25-32-1.33S1.21,14.2,1.87,9.53,1.69,4.13,7.54,3.2c9.87-1.58,30.25-.58,30.25-.58'/%3E%3Cpath class='cls-1' d='M13,17.4s7.06-6.06,13,1.75S15,34,12.37,25.28,15.93,11.84,22.5,13.53,29.25,26,25.62,28.9'/%3E%3Cpath class='cls-1' d='M28.18,8.84s.67,4,2.9,1.85c1.54-1.47.66-3-.9-3.31a2.91,2.91,0,0,0-3,2.44'/%3E%3C/svg%3E") 20 20, pointer;
}
.menu__social-link.menu__social-link--facebook {
  cursor: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%231d1d1b;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eiden_team%3C/title%3E%3Cpath class='cls-1' d='M18.37,35.63c-4.52.9-1.6-4.74-1.31-9.27a8.14,8.14,0,0,0-.52-3.58c-.69-1.05-2.24-.46-3.14-.48-1.3,0-.56-2.94-.47-4.44s3.5-.31,4.25-.62.25-1.27.91-6.72S26,6,27.62,6.23s1.14,3.53.06,4.06c-6-.63-5.36,5.41-4.66,6.59s3.78.39,4.16.69.12,3.88,0,4.31-.15.5-3.15.85-1,7.9-.87,10.56c.06,1.71-2.6,1.93-5.85.78'/%3E%3Cpath class='cls-1' d='M2.68,17.68S.9,4.68,5.37,3.12,27.31,1.24,30.81,1.37,38.25,5,38.68,8.12s1.06,21.66-.62,25.44-8.28,5.56-18.87,5-15.81-2.31-16.5-7.5-3.13-24-1-25.56'/%3E%3C/svg%3E") 20 20, pointer;
}
.menu__social-link.menu__social-link--behance {
  cursor: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%231d1d1b;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eiden_team%3C/title%3E%3Cpath class='cls-1' d='M6.2,1.07S32.08.64,34.45,1.7s4.63,8.56,4.5,19.88-.56,16-5.5,16.75S2.58,39.89,1.7,36.82,1,12.14,1.33,8.2,6,2.39,9.33,2.32'/%3E%3Cpath class='cls-1' d='M5,12.94s.26,15.17,1.26,15.33,10,1.27,11.84-.46,1.5-6.73,0-8.15c-1.16-1.1-1.69-.41.77-3.22,1-1.16,1.21-4.39-.92-5.32C13,9,6.17,9.59,6,10.78s-.5,4.83.75,6.58'/%3E%3Cpath class='cls-1' d='M9.39,16.53S8.9,14,9.86,14s4.46-.42,5.07.38S13.09,17,12.16,17.19c-3,.68-2.82-.94-2.82-.94'/%3E%3Cpath class='cls-1' d='M7.68,21s7-.68,7.83.35.46,3.14-1.06,3.27-4.93,0-5.27-.37,0-3.65,0-3.65'/%3E%3Cpath class='cls-1' d='M24,22.68s9.65.05,10-.69-1.63-7.29-6.82-7-7.7,4.6-5.69,9.76,11.12,3.5,12.58.23c0,0,.62-1.18.15-1.38s-4.37.17-4.48.92c-.26,1.64-3.68.79-4.38.21a3.81,3.81,0,0,1-.9-2.38'/%3E%3Cpath class='cls-1' d='M25.13,20.24s4.66.5,5-.26S26,16.44,25.13,20.24Z'/%3E%3Cpath class='cls-1' d='M23.24,12.43s8.87.36,9.43-.36.87-3.14-8.24-1'/%3E%3C/svg%3E") 20 20, pointer;
}

.hero {
  margin-top: 50px;
}
@media (orientation: portrait), (max-width: 780px) {
  .hero {
    display: flex;
    flex-direction: column;
  }
}
.hero__description {
  margin-left: 50px;
  width: calc(var(--grid-col-withgap) * 2);
}
.hero__description mark {
  background-color: transparent;
}
.hero__description mark:after {
  content: "";
  left: -5px;
  right: -5px;
  bottom: -5px;
  top: -5px;
  background-image: url(../images/mark01.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.hero__description mark:nth-of-type(1)::after {
  background-image: url(../images/mark02.svg);
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__description {
    margin-left: 0;
    margin-right: 20px;
    width: auto;
  }
}
.hero__game-wrapper {
  margin-left: var(--grid-col-width);
  flex-direction: row;
  align-items: flex-start;
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__game-wrapper {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-left: 0;
  }
}
.hero__image-wrapper {
  width: calc(var(--grid-col-withgap) * 3);
  left: 0;
  top: 25%;
  z-index: 2;
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__image-wrapper {
    margin-bottom: 40px;
    width: 100%;
    position: relative;
    order: 2;
  }
}
.hero__showreel-wrapper {
  z-index: 1;
  width: calc(var(--grid-col-withgap) + var(--grid-col-width));
  right: var(--grid-margin);
  top: 0;
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__showreel-wrapper {
    right: unset;
    margin-bottom: 30px;
    width: 100%;
    position: relative;
    order: 4;
  }
}
.hero__title {
  margin-bottom: 20px;
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__title {
    margin-bottom: 10px;
  }
}
.hero__title:nth-of-type(1) {
  margin-left: calc(var(--grid-col-width) + var(--grid-gap));
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__title:nth-of-type(1) {
    margin-left: 0;
  }
}
.hero__title:nth-of-type(2) {
  margin-left: calc(var(--grid-col-withgap) * 3);
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__title:nth-of-type(2) {
    margin-left: var(--grid-col-width);
  }
}
.hero__title:nth-of-type(3) {
  margin-left: calc(var(--grid-col-withgap) * 3);
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__title:nth-of-type(3) {
    margin-left: var(--grid-col-width);
  }
}
.hero__title-wrapper {
  z-index: 3;
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__title-wrapper {
    order: 1;
    margin-bottom: 10px;
  }
}
.hero__title-wrapper::after {
  content: "";
  left: calc(var(--grid-col-width) + var(--grid-gap));
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("../images/UI/hero_line.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.hero__footer {
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 30px;
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__footer {
    margin-bottom: 30px;
    order: 3;
  }
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__footer .hero__game-wrapper {
    display: none;
  }
}
.hero__footer-mobile {
  display: none;
  order: 5;
}
@media (orientation: portrait), (max-width: 780px) {
  .hero__footer-mobile {
    display: block;
  }
}

.videoplayer__placeholder {
  width: 100%;
  height: 100%;
}
.services {
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.services__title-wrapper {
  width: 100%;
  text-align: center;
}

.grid {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 10.4167vw);
  justify-items: center;
  align-content: center;
}
@media (max-width: 1024px) {
  .grid {
    display: flex;
    flex-direction: column;
  }
}
.grid__content {
  width: calc(100% - 9.375vw - 20px);
}
.grid__icon {
  width: 9.375vw;
  height: 0;
  padding-bottom: 9.4271vw;
  background-repeat: no-repeat;
  background-size: auto 9.375vw;
  animation: icon 2s steps(2) infinite;
}
@media (max-width: 1024px) {
  .grid__icon {
    width: 180px;
    padding-bottom: 181px;
    background-position: left center;
    background-size: auto 180px;
    animation-name: iconfix;
  }
}
.grid__item {
  place-items: center;
  border: 3px solid var(--color-second);
  border-radius: 4.1667vw;
  width: 100%;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .grid__item {
    display: flex;
    width: 100%;
    min-height: 140px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 80px;
  }
}
.grid__item:nth-of-type(1) {
  grid-column: 1/4;
  grid-row: 1/3;
}
.grid__item:nth-of-type(1) .grid__item-title {
  color: var(--color-second);
}
.grid__item:nth-of-type(1) .grid__icon {
  background-image: url(../images/services/branding.png);
}
.grid__item:nth-of-type(2) {
  grid-column: 4/7;
  grid-row: 1/3;
  border-color: var(--color-light);
}
.grid__item:nth-of-type(2) .grid__item-title {
  color: var(--color-light);
}
@media (max-width: 1024px) {
  .grid__item:nth-of-type(2) .grid__item-title:after {
    background-image: url(../images/UI/portfolio_arrow_light.svg);
  }
}
.grid__item:nth-of-type(2) .grid__icon {
  background-image: url(../images/services/comunication.png);
}
.grid__item:nth-of-type(3) {
  grid-column: 1/5;
  grid-row: 3/4;
  border-color: var(--color-light);
}
.grid__item:nth-of-type(3) .grid__item-title {
  color: var(--color-light);
}
@media (max-width: 1024px) {
  .grid__item:nth-of-type(3) .grid__item-title:after {
    background-image: url(../images/UI/portfolio_arrow_light.svg);
  }
}
.grid__item:nth-of-type(3) .grid__icon {
  background-image: url(../images/services/web.png);
}
.grid__item:nth-of-type(4) {
  grid-column: 5/7;
  grid-row: 3/4;
}
.grid__item:nth-of-type(4) .grid__item-title {
  color: var(--color-second);
}
.grid__item:nth-of-type(4) .grid__icon {
  background-image: url(../images/services/packaging.png);
}
.grid__item--active .grid__item-title {
  left: 40px;
  transform: translateX(-50%) rotate(-90deg);
}
@media (max-width: 1024px) {
  .grid__item--active .grid__item-title {
    margin-top: 30px;
    position: relative;
    left: unset;
    transform: unset;
    padding-bottom: 20px;
  }
  .grid__item--active .grid__item-title:after {
    display: none;
  }
}
.grid__item--active .grid__item-description {
  flex-direction: row;
  flex-wrap: nowrap;
  visibility: visible;
  animation: show-portfolio 0.3s ease-in-out 0.3s;
  animation-fill-mode: forwards;
}
@media (max-width: 1024px) {
  .grid__item--active .grid__item-description {
    flex-direction: column;
  }
}
.grid__item-description {
  display: none;
  opacity: 0;
  align-items: center;
  width: 100%;
  padding: 20px 50px 20px 100px;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .grid__item-description {
    flex-direction: column;
    padding: 20px;
  }
}
.grid__item-title {
  font-weight: 900;
  font-size: Max(1.823vw, 24px);
  line-height: 30px;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .grid__item-title {
    position: relative;
    padding: 0 0 40px;
  }
  .grid__item-title:after {
    position: absolute;
    content: "";
    background-image: url("../images/UI/portfolio_arrow.svg");
    width: 100%;
    height: 40px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.grid.jsgrid0 .grid__item:nth-of-type(1) {
  background-color: var(--color-second);
  grid-column: 1/5;
}
.grid.jsgrid0 .grid__item:nth-of-type(1) .grid__item-title,
.grid.jsgrid0 .grid__item:nth-of-type(1) .grid__content {
  color: var(--color-white);
}
.grid.jsgrid0 .grid__item:nth-of-type(2) {
  grid-column: 5/7;
}
.grid.jsgrid1 .grid__item:nth-of-type(1) {
  grid-column: 1/3;
}
.grid.jsgrid1 .grid__item:nth-of-type(2) {
  background-color: var(--color-light);
  grid-column: 3/7;
}
.grid.jsgrid1 .grid__item:nth-of-type(2) .grid__item-title {
  color: var(--color-white);
}
.grid.jsgrid2 .grid__item:nth-of-type(1) {
  grid-row: 1/2;
  grid-column: 1/5;
}
.grid.jsgrid2 .grid__item:nth-of-type(2) {
  grid-column: 5/7;
}
.grid.jsgrid2 .grid__item:nth-of-type(3) {
  background-color: var(--color-light);
  grid-row: 2/4;
}
.grid.jsgrid2 .grid__item:nth-of-type(3) .grid__item-title {
  color: var(--color-white);
}
.grid.jsgrid3 .grid__item:nth-of-type(1) {
  grid-column: 1/3;
}
.grid.jsgrid3 .grid__item:nth-of-type(2) {
  grid-column: 3/7;
  grid-row: 1/2;
}
.grid.jsgrid3 .grid__item:nth-of-type(3) {
  grid-column: 1/3;
}
.grid.jsgrid3 .grid__item:nth-of-type(4) {
  background-color: var(--color-second);
  grid-column: 3/7;
  grid-row: 2/4;
}
.grid.jsgrid3 .grid__item:nth-of-type(4) .grid__item-title,
.grid.jsgrid3 .grid__item:nth-of-type(4) .grid__content {
  color: var(--color-white);
}

.awards {
  flex-direction: column;
  align-items: center;
}
@media (orientation: portrait), (max-width: 780px) {
  .awards {
    overflow: hidden;
  }
}
.awards__grid {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 20px;
  width: 100%;
  padding-bottom: 100px;
}
@media (orientation: portrait), (max-width: 780px) {
  .awards__grid {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;
  }
}
.awards__icon {
  width: 100%;
  height: 100%;
}
.awards__item {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  background-color: var(--color-second);
}
.awards__item .awards__multiply {
  color: var(--color-white);
}
.awards__item:nth-of-type(2n + 1) {
  background-color: var(--color-white);
}
.awards__item:nth-of-type(2n + 1) .awards__multiply {
  color: var(--color-main);
}
@media (orientation: portrait), (max-width: 780px) {
  .awards__item:nth-of-type(1) {
    order: 1;
  }
  .awards__item:nth-of-type(2) {
    order: 2;
  }
  .awards__item:nth-of-type(3) {
    order: 4;
  }
  .awards__item:nth-of-type(4) {
    order: 3;
  }
  .awards__item:nth-of-type(5) {
    order: 5;
  }
  .awards__item:nth-of-type(6) {
    order: 6;
  }
}
.awards__item-wrapper {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.awards__multiply {
  z-index: 1;
  padding-top: 20px;
}
.awards__title-wrapper {
  width: 100%;
  text-align: center;
}
.footer {
  height: 243px;
  background-color: var(--color-main);
}
@media (max-width: 1024px) {
  .footer {
    height: auto;
  }
}
.footer__link {
  font-weight: 300;
}
.footer__row {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .footer__row:nth-of-type(n + 2) {
    flex-direction: column;
    margin-top: 50px;
  }
}
.footer__social {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
@media (max-width: 1024px) {
  .footer__social {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer__socila-item {
  margin-left: 40px;
}
@media (max-width: 1024px) {
  .footer__socila-item {
    margin-left: 0;
  }
}
.footer__wrapper {
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 0 30px;
  height: 100%;
}
.footer__writeitem {
  margin-right: 34px;
}
.footer__writeus {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
@media (max-width: 1024px) {
  .footer__writeus {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer__row--policy {
  justify-content: flex-end;
}
.footer__row--policy * {
  color: var(--color-second);
}

.jsPopupWrapper {
  position: fixed;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  background-color: var(--color-white);
}

.jsPopupVideoWrapper {
  width: 100%;
  height: 100%;
  padding: 5.9896vw calc(var(--grid-gap) + var(--grid-col-width));
}

.cookies {
  position: fixed;
  left: var(--grid-margin);
  bottom: 0;
  z-index: 999;
}
@media (max-width: 1024px) {
  .cookies__button {
    padding: 9px;
  }
}
.cookies__close-wrapper {
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: Max(2.3438vw, 45px);
  height: Max(2.3438vw, 45px);
  cursor: pointer;
}
.cookies__image-wrapper {
  left: 10px;
  bottom: 20px;
}
.cookies__text {
  padding-right: 20px;
}
.cookies__wrapper {
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px 80px;
  border-radius: 50px;
  background-color: var(--color-light);
}
@media (max-width: 1024px) {
  .cookies__wrapper {
    align-items: center;
  }
}
.portfolio {
  padding-bottom: 100px;
}
.portfolio__credentials {
  margin-left: var(--grid-col-width);
  align-self: flex-end;
}
.portfolio__credentials::after {
  content: "";
  right: 0;
  top: 50%;
  bottom: 0;
  width: var(--grid-col-width);
  transform: translateX(100%) translateY(-50%);
  background-image: url(../images/arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.3s ease-in-out;
}
@media (orientation: portrait), (max-width: 780px) {
  .portfolio__credentials::after {
    transform: translateY(-50%);
    width: 50%;
    height: 30px;
    background-position: left;
  }
}
.portfolio__credentials:hover::after {
  transform: translateX(140%) translateY(-50%);
}
@media (orientation: portrait), (max-width: 780px) {
  .portfolio__credentials:hover::after {
    transform: translateY(-50%);
  }
}
@media (orientation: portrait), (max-width: 780px) {
  .portfolio__credentials {
    align-self: stretch;
    margin-left: 0;
    margin-top: 40px;
  }
  .portfolio__credentials br {
    display: none;
  }
}
.portfolio__title {
  color: var(--color-main);
  line-height: 4.6875vw;
}
.portfolio__title:nth-of-type(1) {
  margin-bottom: 30px;
  margin-left: var(--grid-col-withgap);
}
@media (orientation: portrait), (max-width: 780px) {
  .portfolio__title:nth-of-type(1) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}
.portfolio__title:not(:nth-of-type(1)) {
  margin-left: calc(var(--grid-col-withgap) * 2);
}
@media (orientation: portrait), (max-width: 780px) {
  .portfolio__title:not(:nth-of-type(1)) {
    margin-left: 0;
  }
}
.portfolio__title:nth-of-type(3) {
  margin-bottom: 0;
}
.portfolio__title:nth-of-type(3)::after {
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-image: url("../images/UI/projects_line.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(100%);
}
@media (orientation: portrait), (max-width: 780px) {
  .portfolio__title {
    margin-bottom: 30px;
  }
}
.portfolio__titles {
  flex-direction: row;
  flex-wrap: wrap;
}
@media (orientation: portrait), (max-width: 780px) {
  .portfolio__titles {
    flex-direction: column;
  }
}
.portfolio__title-link {
  display: inline-block;
  text-decoration: none;
  border: 3px solid var(--color-second);
  border-radius: 80px;
  line-height: 4.1667vw;
  margin-bottom: 0;
  margin-top: 25px;
  padding: 10px 20px 20px;
}
@media (orientation: portrait), (max-width: 780px) {
  .portfolio__title-link {
    margin-top: 20px;
    width: 80%;
    text-align: center;
    align-self: center;
    padding: 20px;
  }
}

.jsHoverPortfolio,
.jsPortfolio {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.jsPortfolioImage {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.live__title-wrapper {
  text-align: center;
}
.live__content {
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1024px) {
  .live__content {
    display: none;
  }
}
.live__grid {
  grid-template-columns: repeat(3, var(--grid-col-width)) calc(var(--grid-col-width) + var(--grid-col-withgap)) repeat(3, var(--grid-col-width));
  gap: var(--grid-gap);
  grid-template-rows: 1fr;
  height: 60vh;
}
@media (max-width: 1024px) {
  .live__grid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10vw;
    grid-template-rows: 0.5fr 1fr 0.5fr;
    height: auto;
  }
}
.live__grid-image {
  height: auto;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .live__grid-image {
    position: relative;
  }
}
.live__grid-item {
  cursor: pointer;
  width: 100%;
}
.live__grid-item--preview {
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1024px) {
  .live__grid-item--preview {
    grid-column: 1/4;
  }
  .live__grid-item--preview .live__preview-button-wrapper {
    display: none;
  }
}
.live__grid-item:nth-of-type(1) .live__grid-image {
  top: 10%;
  transform: translateY(-10%);
}
.live__grid-item:nth-of-type(2) .live__grid-image {
  top: 90%;
  transform: translateY(-90%);
}
.live__grid-item:nth-of-type(3) .live__grid-image {
  top: 0;
  transform: translateY(0);
}
@media (max-width: 1024px) {
  .live__grid-item:nth-of-type(3) .live__grid-image {
    transform: translateY(20%);
  }
}
.live__grid-item:nth-of-type(5) .live__grid-image {
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1024px) {
  .live__grid-item:nth-of-type(5) .live__grid-image {
    transform: translateY(0);
  }
}
.live__grid-item:nth-of-type(6) .live__grid-image {
  top: 70%;
  transform: translateY(-70%);
}
@media (max-width: 1024px) {
  .live__grid-item:nth-of-type(6) .live__grid-image {
    top: 0;
    transform: translateY(0);
  }
}
.live__grid-item:nth-of-type(7) .live__grid-image {
  top: -10%;
  transform: translateY(10%);
}
@media (max-width: 1024px) {
  .live__grid-item:nth-of-type(7) .live__grid-image {
    top: 30%;
    transform: unset;
  }
}
@media (max-width: 1024px) {
  .live__grid-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.live__preview-button-wrapper {
  margin-top: 40px;
}
@media (max-width: 1024px) {
  .live__preview-wrapper {
    width: 100%;
  }
}
.live__preview-button-tablet {
  display: none;
}
@media (max-width: 1024px) {
  .live__preview-button-tablet {
    display: inline-block;
  }
}
.live__line:nth-of-type(1) {
  left: 0;
  top: 20px;
  bottom: 0;
  width: calc(var(--grid-margin) + var(--grid-col-withgap) + var(--grid-col-width));
}
.live__line:nth-of-type(2) {
  left: calc(var(--grid-margin) + var(--grid-col-withgap) - var(--grid-gap) / 2);
  top: 100px;
  height: auto;
  width: calc(var(--grid-col-withgap) * 3);
}
.live__line:nth-of-type(3) {
  right: 0;
  top: 80px;
  width: calc(var(--grid-col-withgap) * 3 + var(--grid-margin) + var(--grid-col-width));
}
.live__lines {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.submit-form {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-light);
  z-index: 10005;
  padding: 0 var(--grid-col-withgap) 0;
}
.submit-form__close-wrapper {
  right: 25px;
  top: 25px;
  width: Max(2.3438vw, 45px);
  height: Max(2.3438vw, 45px);
  cursor: pointer;
}
.submit-form__title {
  color: var(--color-second);
}
.submit-form__title-wrapper {
  padding-top: 5vh;
  margin-bottom: 8vh;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (max-width: 1024px) {
  .submit-form__title-wrapper {
    padding-top: 95px;
  }
}
.submit-form__wrapper {
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.submit-form__wrapper::-webkit-scrollbar {
  display: none;
}
.submit-form__description {
  color: var(--color-white);
}
.submit-form__thankyou {
  visibility: hidden;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 var(--grid-col-withgap);
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light);
}
@media (max-width: 1024px) {
  .submit-form__thankyou {
    flex-direction: column;
    padding: 0 var(--grid-gap);
  }
}
.form {
  flex-direction: column;
}
.form__group {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 5vh;
}
.form__group:not(:last-of-type) .form__item::after {
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.form__group:nth-of-type(1) .form__item::after {
  background-image: url(../images/UI/form01.svg);
}
.form__group:nth-of-type(2) .form__item::after {
  background-image: url(../images/UI/form02.svg);
}
.form__group:nth-of-type(3) .form__item::after {
  background-image: url(../images/UI/form03.svg);
}
.form__group:nth-of-type(4) .form__item::after {
  background-image: url(../images/UI/form04.svg);
}
@media (max-width: 1024px) {
  .form__group {
    flex-direction: column;
    align-items: flex-start;
  }
}
.form__item {
  width: 100%;
  margin-left: 20px;
}
@media (max-width: 1024px) {
  .form__item {
    margin-left: 0;
  }
}
.form__item-desc {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.form__label {
  align-items: center;
  white-space: nowrap;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  color: var(--color-white);
}
.form input,
.form textarea {
  border-radius: 0 !important;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  padding: 10px 10px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  border: none;
  text-align: center;
}
.form input:focus,
.form textarea:focus {
  outline: none;
  box-shadow: none;
}
.form input[type=submit] {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: transparent !important;
  -webkit-appearance: none;
}
.form__submit {
  margin-top: 25px;
  text-align: center;
  align-self: center;
}

.jsTeamWrapper, .jsTeamhover {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.jsTeamImage {
  transform-origin: bottom center;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}