.submit-form {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-light);
  z-index: 10005;
  @extend %wrapper;
  padding: 0 var(--grid-col-withgap) 0;
  &__close {
    @extend %cover;
  }
  &__close-wrapper {
    @extend %absolute;
    right: 25px;
    top: 25px;

    width: toVw(45, 45);
    height: toVw(45, 45);
    cursor: pointer;
  }
  &__form {
  }
  &__title {
    @extend %font-hero-title;
    color: var(--color-second);
  }
  &__title-wrapper {
    padding-top: 5vh;
    margin-bottom: 8vh;
    @extend %flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @include tablet {
      padding-top: 95px;
    }
  }
  &__wrapper {
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__col {
  }
  &__description {
    @extend %font-subtitle;
    color: var(--color-white);
  }
  &__img {
    @extend %contain;
  }
  &__img-wrapper {
  }
  &__thankyou {
    visibility: hidden;
    opacity: 0;
    @extend %absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 var(--grid-col-withgap);
    @extend %flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    background-color: var(--color-light);
    @include tablet {
      flex-direction: column;
      padding: 0 var(--grid-gap);
    }
  }
  &__title {
    @extend %font-title;
  }
}
