.jsTeamWrapper {
  @extend %absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.jsTeamhover {
  @extend .jsTeamWrapper;
}
.jsTeamImage {
  @extend %absolute;
  transform-origin: bottom center;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}
