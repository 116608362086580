.cookies {
  position: fixed;
  left: var(--grid-margin);
  bottom: 0;
  z-index: 999;
  &__button {
    @include tablet {
      padding: 9px;
    }
  }
  &__close-wrapper {
    @extend %absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: toVw(45, 45);
    height: toVw(45, 45);
    cursor: pointer;
  }
  &__close-icon {
    @extend %contain;
  }
  &__img {
  }
  &__image-wrapper {
    @extend %absolute;
    left: 10px;
    bottom: 20px;
  }
  &__text {
    padding-right: 20px;
  }
  &__wrapper {
    @extend %flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px 80px;
    border-radius: 50px;
    background-color: var(--color-light);
    @include tablet {
      align-items: center;
    }
  }
  &__position {
    @extend %relative;
  }
}
