// MEDIA
@mixin mobile {
  @media (orientation: portrait), (max-width: 780px) {
    @content;
  }
}
%mobile {
  @include mobile;
}

@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
%tablet {
  @include tablet;
}
@mixin relative {
  position: relative;
}
%relative {
  @include relative;
}

@mixin absolute {
  position: absolute;
}
%absolute {
  @include absolute;
}

@mixin flex {
  display: flex;
}
%flex {
  @include flex;
}

@mixin grid {
  display: grid;
}
%grid {
  @include grid;
}

// FONTS
@mixin font-hero-title {
  font-size: toVw(140, 40);
  line-height: toVw(120, 40);
}
%font-hero-title {
  @include font-hero-title;
}

@mixin font-title {
  font-size: toVw(170, 52);
  line-height: toVw(160, 52);
  color: var(--color-second);
  margin-bottom: 50px;
  @include mobile {
    margin-bottom: 30px;
  }
}
%font-title {
  @include font-title;
}

@mixin font-subtitle {
  font-size: toVw(35, 20);
  line-height: toVw(40, 30);
  font-weight: 900;
}
%font-subtitle {
  @include font-subtitle;
}

@mixin font-p {
  font-size: toVw(20, 17);
  line-height: toVw(26, 22);
}
%font-p {
  @include font-p;
}
@mixin font-menu {
  font-size: toVw(80, 36);
  line-height: toVw(80, 30);
}
%font-menu {
  @include font-menu;
}

@mixin font-menu-contact {
  font-size: toVw(38, 20);
  line-height: toVw(50, 30);
}
%font-menu-contact {
  @include font-menu-contact;
}

@mixin font-form-label {
  font-size: toVw(80, 35);
  line-height: toVw(80, 35);
}
%font-form-label {
  @include font-form-label;
}

// Wrapper
@mixin wrapper {
  width: 100%;
  padding: 0 var(--grid-margin);
}
%wrapper {
  @include wrapper;
}
@mixin section {
  margin-bottom: 154px;
  @include mobile {
    margin-bottom: 80px;
  }
}
%section {
  @include section;
}

@mixin wrapper-fix {
  width: min(90%, 1024px);
}
%wrapper-fix {
  @include wrapper-fix;
}
@mixin wrapper-fix-small {
  width: min(80%, 700px);
}
%wrapper-fix-small {
  @include wrapper-fix-small;
}

// object fit
@mixin contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
%contain {
  @include contain;
}

@mixin cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
%cover {
  @include cover;
}

// Service grid

@mixin color-light {
  color: var(--color-white);
}
%color-light {
  @include color-light;
}

@mixin color-red {
  color: var(--color-second);
}
%color-red {
  @include color-red;
}

// Disable selection
@mixin no-select {
  user-select: none;
}
%no-select {
  @include no-select;
}

@mixin disable-events {
  pointer-events: none;
}
%disable-events {
  @include disable-events;
}
