// Colors
$color-bg: #efedea;
$color-main: #4d423d;
$color-second: #bf6b47;
$color-light: #e8be9e;

// Grid
$grid-margin: toVw(100);
$columns: 8;
$grid-gap: toVw(80);
$grid-col-width: decimal-ceil(
  calc((100vw - ($grid-margin * 2) - ($grid-gap * 7)) / $columns),
  4
);
$grid-col-withgap: decimal-ceil(calc($grid-col-width + $grid-gap), 4);

// CSS variables
// https://greensock.com/forums/topic/21422-how-to-animate-using-css-variables-as-target-values/
// https://greensock.com/css-variables/
:root {
  --color-bg: #{$color-bg};
  --color-white: #{$color-bg};
  --color-main: #{$color-main};
  --color-second: #{$color-second};
  --color-light: #{$color-light};

  // gird
  --grid-margin: #{$grid-margin};
  --grid-columns: #{$columns};
  --grid-gap: #{$grid-gap};
  --grid-col-width: #{$grid-col-width};
  --grid-col-withgap: #{$grid-col-withgap};
}

body,
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  // -webkit-text-fill-color: rgba($color: #ffffff, $alpha: 0.5);
  -webkit-box-shadow: 0 0 0px 1000px rgba($color: #ffffff, $alpha: 0.5) inset;
  transition: background-color 5000s ease-in-out 0s;
}
