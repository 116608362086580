.awards {
  $self: &;
  @extend %relative;
  @extend %section;
  @extend %flex;
  flex-direction: column;
  align-items: center;
  @include mobile {
    overflow: hidden;
  }
  &__grid {
    @extend %grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 20px;
    // width: calc(var(--grid-col-withgap) * 4 - var(--grid-gap));
    width: 100%;
    padding-bottom: 100px;
    @include mobile {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 0;
    }
  }
  &__icon {
    @extend %absolute;
    width: 100%;
    height: 100%;
  }
  &__image {
    @extend %cover;
    // mix-blend-mode: multiply;
  }
  &__item {
    @extend %relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
    background-color: var(--color-second);
    #{$self}__multiply {
      color: var(--color-white);
    }
    &:nth-of-type(2n + 1) {
      background-color: var(--color-white);
      #{$self}__multiply {
        color: var(--color-main);
      }
    }
    @include mobile {
      &:nth-of-type(1) {
        order: 1;
      }
      &:nth-of-type(2) {
        order: 2;
      }
      &:nth-of-type(3) {
        order: 4;
      }
      &:nth-of-type(4) {
        order: 3;
      }
      &:nth-of-type(5) {
        order: 5;
      }
      &:nth-of-type(6) {
        order: 6;
      }
    }
  }
  &__item-wrapper {
    @extend %absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @extend %flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  &__multiply {
    @extend %font-subtitle;
    z-index: 1;
    padding-top: 20px;
  }
  &__title {
    @extend %font-title;
  }
  &__title-wrapper {
    width: 100%;
    text-align: center;
  }
  &__wrapper {
    @extend %wrapper-fix-small;
  }
}
