.grid {
  $self: &;
  @extend %grid;
  @extend %relative;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, toVw(200));
  justify-items: center;
  align-content: center;
  @include tablet {
    display: flex;
    flex-direction: column;
  }
  &__content {
    width: calc(100% - toVw(180) - 20px);
  }
  &__icon {
    width: toVw(180);
    height: 0;
    padding-bottom: toVw(181);
    background-repeat: no-repeat;
    // background-position: 0 0;
    background-size: auto toVw(180);
    animation: icon 2s steps(2) infinite;

    @include tablet {
      width: 180px;
      padding-bottom: 181px;
      background-position: left center;
      background-size: auto 180px;
      animation-name: iconfix;
    }
  }
  &__image {
  }
  &__item {
    @extend %grid;
    @extend %relative;
    place-items: center;
    border: 3px solid var(--color-second);
    border-radius: toVw(80);
    width: 100%;
    cursor: pointer;
    @include tablet {
      display: flex;
      width: 100%;
      min-height: 140px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 80px;
    }
    &:nth-of-type(1) {
      grid-column: 1/4;
      grid-row: 1/3;
      // height: toVw(320);
      #{$self}__item-title {
        color: var(--color-second);
      }
      #{$self}__icon {
        background-image: url(../images/services/branding.png);
      }
    }
    &:nth-of-type(2) {
      grid-column: 4/7;
      grid-row: 1/3;
      // height: toVw(320);
      border-color: var(--color-light);
      #{$self}__item-title {
        color: var(--color-light);
        @include tablet {
          &:after {
            background-image: url(../images/UI/portfolio_arrow_light.svg);
          }
        }
      }
      #{$self}__icon {
        background-image: url(../images/services/comunication.png);
      }
    }
    &:nth-of-type(3) {
      grid-column: 1/5;
      grid-row: 3/4;
      // height: toVw(200);
      border-color: var(--color-light);
      #{$self}__item-title {
        color: var(--color-light);
        @include tablet {
          &:after {
            background-image: url(../images/UI/portfolio_arrow_light.svg);
          }
        }
      }
      #{$self}__icon {
        background-image: url(../images/services/web.png);
      }
    }
    &:nth-of-type(4) {
      grid-column: 5/7;
      grid-row: 3/4;
      // height: toVw(200);
      #{$self}__item-title {
        color: var(--color-second);
      }
      #{$self}__icon {
        background-image: url(../images/services/packaging.png);
      }
    }
    &--active {
      #{$self}__item-title {
        @extend %absolute;
        left: 40px;
        transform: translateX(-50%) rotate(-90deg);
        @include tablet {
          margin-top: 30px;
          position: relative;
          left: unset;
          transform: unset;
          padding-bottom: 20px;
          &:after {
            display: none;
          }
        }
      }
      #{$self}__item-description {
        @extend %flex;
        flex-direction: row;
        flex-wrap: nowrap;
        visibility: visible;
        @include tablet {
          flex-direction: column;
        }
        animation: show-portfolio 0.3s ease-in-out 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }

  &__item-description {
    display: none;
    opacity: 0;
    align-items: center;
    width: 100%;
    padding: 20px 50px 20px 100px;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
      padding: 20px;
    }
  }
  &__item-title {
    font-weight: 900;
    font-size: toVw(35, 24);
    line-height: 30px;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
    @include tablet {
      position: relative;
      padding: 0 0 40px;
      &:after {
        position: absolute;
        content: "";
        background-image: url("../images/UI/portfolio_arrow.svg");
        width: 100%;
        height: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      // height: 140px;
    }
  }
  // modifiers
  &.jsgrid0 {
    #{$self}__item {
      &:nth-of-type(1) {
        background-color: var(--color-second);
        grid-column: 1/5;
        #{$self}__item-title,
        #{$self}__content {
          color: var(--color-white);
        }
      }
      &:nth-of-type(2) {
        grid-column: 5/7;
      }
    }
  }
  &.jsgrid1 {
    #{$self}__item {
      &:nth-of-type(1) {
        grid-column: 1/3;
      }
      &:nth-of-type(2) {
        background-color: var(--color-light);
        grid-column: 3/7;
        #{$self}__item-title {
          color: var(--color-white);
        }
      }
    }
  }
  &.jsgrid2 {
    #{$self}__item {
      &:nth-of-type(1) {
        grid-row: 1/2;
        grid-column: 1/5;
      }
      &:nth-of-type(2) {
        grid-column: 5/7;
      }
      &:nth-of-type(3) {
        background-color: var(--color-light);
        grid-row: 2/4;
        #{$self}__item-title {
          color: var(--color-white);
        }
      }
    }
  }
  &.jsgrid3 {
    #{$self}__item {
      &:nth-of-type(1) {
        grid-column: 1/3;
      }
      &:nth-of-type(2) {
        grid-column: 3/7;
        grid-row: 1/2;
      }
      &:nth-of-type(3) {
        grid-column: 1/3;
      }
      &:nth-of-type(4) {
        background-color: var(--color-second);
        grid-column: 3/7;
        grid-row: 2/4;
        #{$self}__item-title,
        #{$self}__content {
          color: var(--color-white);
        }
      }
    }
  }
}
