.portfolio {
  @extend %wrapper;
  @extend %relative;
  @extend %section;
  padding-bottom: 100px;
  &__credentials {
    @extend %relative;
    margin-left: var(--grid-col-width);
    align-self: flex-end;
    &::after {
      content: "";
      @extend %absolute;
      right: 0;
      top: 50%;
      bottom: 0;
      width: var(--grid-col-width);
      transform: translateX(100%) translateY(-50%);
      background-image: url(../images/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 0.3s ease-in-out;
      @include mobile {
        transform: translateY(-50%);
        width: 50%;
        height: 30px;
        background-position: left;
      }
    }
    &:hover::after {
      transform: translateX(140%) translateY(-50%);
      @include mobile {
        transform: translateY(-50%);
      }
    }
    @include mobile {
      align-self: stretch;
      margin-left: 0;
      margin-top: 40px;
      br {
        display: none;
      }
    }
  }
  &__title {
    @extend %font-title;
    color: var(--color-main);
    line-height: toVw(90);
    &:nth-of-type(1) {
      margin-bottom: 30px;
      margin-left: var(--grid-col-withgap);
      @include mobile {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
      }
    }
    &:not(:nth-of-type(1)) {
      margin-left: calc(var(--grid-col-withgap) * 2);
      @include mobile {
        margin-left: 0;
      }
    }
    &:nth-of-type(3) {
      @extend %relative;
      margin-bottom: 0;
      &::after {
        content: "";
        @extend %absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
        background-image: url("../images/UI/projects_line.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(100%);
      }
    }
    @include mobile {
      margin-bottom: 30px;
    }
  }
  &__titles {
    @extend %flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include mobile {
      flex-direction: column;
    }
  }
  &__wrapper {
  }
  &__title-link {
    @extend %font-title;
    @extend %relative;
    display: inline-block;
    text-decoration: none;
    border: 3px solid var(--color-second);
    border-radius: 80px;
    line-height: toVw(80);
    margin-bottom: 0;
    margin-top: 25px;
    padding: 10px 20px 20px;
    @include mobile {
      margin-top: 20px;
      width: 80%;
      text-align: center;
      align-self: center;
      padding: 20px;
    }
  }
}

.jsHoverPortfolio,
.jsPortfolio {
  @extend %absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.jsPortfolioImage {
  @extend %absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}
