.videoplayer {
  &__image {
    @extend %disable-events;
  }
  &__placeholder {
    width: 100%;
    height: 100%;
    * {
      @extend %contain;
    }
  }
}
