@font-face {
  font-family: "CASlalom";
  src: url("../fonts/CASlalom-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "CASlalom";
  src: url("../fonts/CASlalom-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
html {
  font-size: 18px;
}

* {
  font-family: CASlalom, sans-serif;
  color: var(--color-main);
  font-weight: 300;
  @extend %font-p;
}
