@keyframes icon {
  from {
    background-position: 0 0;
  }
  to {
    background-position: calc(toVw(180) * -2) 0;
  }
}
@keyframes iconfix {
  from {
    background-position: 0 0;
  }
  to {
    background-position: calc(180px * -2) 0;
  }
}

@keyframes show-portfolio {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
