.services {
  @extend %flex;
  @extend %section;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &__grid {
  }
  &__grid-wrapper {
    // width: calc(var(--grid-col-withgap) * 5 + var(--grid-col-width));
    @extend %wrapper-fix;
  }
  &__title {
    @extend %font-title;
  }
  &__title-wrapper {
    width: 100%;
    text-align: center;
  }
}
