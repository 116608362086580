.cursor {
  $self: &;
  position: fixed;
  width: toVw(90, 90);
  height: toVw(90, 90);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  transform: translate(-50%, -50%);
  @extend %disable-events;
  &--close {
    #{$self}__image {
      background-image: url("../images/UI/close_button.svg");
    }
  }
  &--play {
    #{$self}__image {
      background-image: url("../images/UI/play_button.svg");
    }
  }
  &--stop {
  }
  &__image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__wrapper {
    width: 100%;
    height: 100%;
  }
}
