.button {
  $self: &;
  @extend %relative;
  display: inline-block;
  text-align: center;
  padding: 13px 35px;
  border-radius: 20vmin;
  border: 3px solid var(--color-main);
  cursor: pointer;
  z-index: 1;
  &__switch {
    font-size: 18px;
    line-height: 18px;
    font-weight: 900;
    &::after {
      content: "";
      @extend %absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: calc(28px + 1rem);
      border-radius: 28px;
      background-color: var(--color-light);
      transition: width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      z-index: -1;
    }
  }
  &:hover {
    #{$self}__switch::after {
      width: 100%;
    }
  }
  &--invert {
    border-color: var(--color-light);
    #{$self}__switch::after {
      background-color: var(--color-second);
    }
  }
  &--red {
    // border-color: var(--color-light);
    #{$self}__switch::after {
      background-color: var(--color-second);
    }
  }
  &--light {
    border-color: var(--color-white);
    * {
      color: var(--color-white);
    }
    #{$self}__switch::after {
      background-color: var(--color-light);
    }
  }
  &--lang {
    padding: 6px 20px;
    border: none;
    #{$self}__switch::after {
      width: 38px;
    }
  }
}
