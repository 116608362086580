.form {
  $self: &;
  @extend %flex;
  flex-direction: column;
  &__group {
    @extend %relative;
    @extend %flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 5vh;
    &:not(:last-of-type) #{$self}__item::after {
      content: "";
      @extend %absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    &:nth-of-type(1) {
      #{$self}__item::after {
        background-image: url(../images/UI/form01.svg);
      }
    }
    &:nth-of-type(2) {
      #{$self}__item::after {
        background-image: url(../images/UI/form02.svg);
      }
    }
    &:nth-of-type(3) {
      #{$self}__item::after {
        background-image: url(../images/UI/form03.svg);
      }
    }
    &:nth-of-type(4) {
      #{$self}__item::after {
        background-image: url(../images/UI/form04.svg);
      }
    }
    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__item {
    width: 100%;
    margin-left: 20px;
    @extend %relative;
    @include tablet {
      margin-left: 0;
    }
  }
  &__item-desc {
    @extend %absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  &__label {
    // @extend %absolute;
    @extend %flex;
    @extend %font-form-label;
    align-items: center;
    white-space: nowrap;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    color: var(--color-white);
  }
  input,
  textarea {
    @extend %relative;
    border-radius: 0 !important;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    padding: 10px 10px;
    margin: 0;
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    border: none;
    text-align: center;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  input[type="submit"] {
    @extend %absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: transparent !important;
    -webkit-appearance: none;
  }
  &__submit {
    margin-top: 25px;
    text-align: center;

    align-self: center;
  }
}
